// Terms page
import React from "react";
import Layout from "../../components/Layout";
import TermsOfUse from "../../config/staticRefs/AXLRY-TermsOfUse-12.3.18.pdf";
import { isMobileOnly } from "react-device-detect";

const Terms = () => {
    return (
        <Layout title={"AXLRY - Terms of Use"}>
            <div
                className={"center-container"}
                style={{
                    marginTop: "85px",
                    justifyItems: "flex-start",
                    padding: isMobileOnly ? 0 : 8
                }}
            >
                <p>
                    <strong>AXLRY, LLC &ndash; TERMS OF USE </strong>
                    <a href={TermsOfUse} download="Axlry-TermsOfUse">
                        (Download the PDF)
                    </a>
                </p>
                <p>Effective Date: December 3, 2018</p>
                <ol>
                    <li>
                        <strong>
                            Contractual Relationship and Acceptance of Terms of
                            Use
                        </strong>
                    </li>
                </ol>
                <p>
                    AXLRY, LLC, an Illinois Limited Liability Company,
                    <strong>(hereinafter &ldquo;AXLRY&rdquo;)</strong> owns and
                    operates the smart phone application,{" "}
                    <strong>(hereinafter the &ldquo;App&rdquo;)</strong> (and
                    any sites or mobile applications AXLRY has now or in the
                    future) that references these Terms of Use. These Terms of
                    Use
                    <strong>(hereinafter &ldquo;Terms&rdquo;)</strong> act as an
                    agreement and govern the access and/or use by you, an
                    individual, or you, as officer, director, contractor,
                    employee, agent, representative or owner, of a business
                    entity in any form, <strong>(hereinafter</strong>
                    <strong>&ldquo;User&rdquo;)</strong>, from within the United
                    States and its territories. By User&rsquo;s access and/or
                    use ofthe AXLRY App, and/or possible Third Party information
                    or services
                    <strong>(hereinafter &ldquo;Third</strong>
                    <strong>Party Info/Services&rdquo;) </strong>made available
                    through the App, constitutes User&rsquo;s agreementwith
                    these Terms as made available and viewable on the AXLRY App.
                    User acknowledges and agrees a contractual relationship
                    between User and AXLRY, LLC. User&rsquo;s continued use
                    and/or access of the App, its Services and/or Third Party
                    Info/ Services constitutes that User will regularly visit
                    the Terms to familiarize User with any updates or
                    amendments. (
                    <em>
                        The term &ldquo;use&rdquo; or &ldquo;using&rdquo; also
                        includes any and all use,
                    </em>
                    <em>
                        access, subscription, by any means, by any person,
                        entity, or agent of an entity.)
                    </em>
                </p>
                <p>
                    Also, please find AXLRY Privacy Policy by contacting us, or
                    following the link that can be found here:
                    <a href="https://axlry.com/support/privacy">
                        https://www.axlry.com/support/privacy
                    </a>
                </p>
                <p>
                    IF USER DOES NOT AGREE TO THESE TERMS (AND/OR PRIVACY
                    POLICY), USER CANNOT CONTINUE ACCESS AND/OR USE OF THE APP,
                    THE SERVICE OF AXLRY, OR A THIRD PARTY INFO/SERVICES. IF
                    USER DOES NOT AGREE TO THESE TERMS, IMMEDIATELY STOP USING
                    THE APP, AXLRY&rsquo;S SERVICE OFFERED THROUGH THE APP, AND
                    ANY THIRD PARTY INFO/SERVICES PROVIDED BY AXLRY OR THIRD
                    PARTY. ADDITIONALLY, PLEASE NOTIFY AXLRY OF USER&rsquo;S
                    SPECIFIC DISAGREEMENT WITH THESE TERMS BY CONTACTING:
                    <strong>support@axlry.com</strong> <strong>. </strong>THESE
                    TERMS EXPRESSLY SUPERSEDE PRIOR TERMS OFUSE, AGREEMENTS OR
                    ARRANGEMENTS WITH USER. AXLRY RESERVES THE RIGHT TO
                    IMMEDIATELY MODIFY, AMEND OR TERMINATE THESE TERMS OR CEASE
                    ANY SERVICE WITH RESPECT TO USER AT ANY TIME FOR ANY REASON.{" "}
                    <br />
                    <br />
                </p>
                <ol start="2">
                    <li>
                        <strong> Service</strong>
                    </li>
                </ol>
                <p>
                    The specific Service
                    <strong>(hereinafter &ldquo;Service&rdquo;)</strong> AXLRY
                    provides constitutes offering a digital platform that
                    enables Users of the App to create and modify User profiles
                    (Artist or Director), upload, share, or link User&rsquo;s
                    with additional content, media, music, videos, story-boards,
                    treatments, to discover, match and connect with other Users
                    who in turn provides their own service including but not
                    limited to: VIDEO AND MEDIA PRODUCTION, or MUSIC AND
                    PERFORMANCES or APPEARANCES
                </p>
                <p>
                    <strong>(hereinafter &ldquo;User Services&rdquo;).</strong>
                </p>
                <p>
                    AXLRY IS NOT AN ARTIST AND DOES NOT PROVIDE ITS OWN VIDEO OR
                    MEDIA PRODUCTION SERVICE, AND AXLRY IS NOT A VIDEO OR MEDIA
                    PRODUCTION COMPANY. IT IS UP TO A USER, WHO MAY BE
                    DISCOVERED, MATCHED AND CONNECTED THROUGH THE USE OF THE
                    AXLRY APP AND/OR SERVICE, TO OFFER VIDEO AND/OR MEDIA
                    PRODUCTION SERVICE WHICH MAY BE OBTAINED OR ARRANGED THROUGH
                    THE USE OF THE AXLRY APP AND/OR SERVICE. AXLRY DOES NOT, AND
                    DOES NOT INTEND TO ACT AS ITS OWN VIDEO OR MEDIA PRODUCTION
                    COMPANY, AND HAS NO RESPONSIBILITY OR LIABILITY FOR ANY USER
                    ACTION OR INACTION OUTSIDE THE EXPECTED USER BEHAVIOR UNDER
                    THESE TERMS OF USE.
                </p>
                <p>
                    <br />
                </p>
                <ol>
                    <li>
                        <strong> Relationship between USERs and AXLRY</strong>
                    </li>
                </ol>
                <p>
                    If you are a User of the AXLRY App, you acknowledge and
                    agree you are not an employee, owner, joint venture, partner
                    or agent of AXLRY, and that there is no employment agreement
                    between USER and AXLRY. In addition, if you are a User, you
                    further understand and agree that you have no authority to
                    bind AXLRY and you will not make any representations to any
                    party that you have any authority to bind AXLRY, as an
                    employee, partner, agent or otherwise. User acknowledges and
                    agrees that AXLRY&rsquo;s provision of Service to Users
                    creates a direct business relationship between User and
                    AXLRY. User acknowledges and agrees that AXLRY is not
                    responsible or liable for the actions or inactions of a USER
                    in relation to AXLRY, User Services or possible Third Party
                    Services. User shall have the sole responsibility for any
                    obligations or liabilities to a Third Party that arise. User
                    acknowledges and agrees that User is solely responsible for
                    taking such precaution as may be reasonable, necessary and
                    proper (including asking User or Third Parties about
                    payment, adequate training, licenses, permits and insurance
                    that meets the requirements of all applicable laws and
                    regulations) regarding any acts or omissions of a USER or
                    Third Party. User acknowledges and agrees that User will not
                    request any unlawful services from another USER. User
                    acknowledges and agrees that upon User&rsquo;s request User
                    Services may provided to User, but is not guaranteed. User
                    further expressly agrees that any User Services requested
                    from any User that were discovered, matched, or connected
                    through the AXLRY App, will bearranged through the AXLRY
                    App. Once a User is or has been discovered or connected with
                    another User, any effort or attempt by User to avoid going
                    through the AXLRY App to request User Services or any lawful
                    services from User or Third Party will be deemed a breach of
                    these Terms. User&rsquo;s account will immediately be
                    suspended.
                </p>
                <p>
                    <br />
                </p>
                <ol start="3">
                    <li>
                        <strong> Third Party Beneficiaries</strong>
                    </li>
                </ol>
                <p>
                    User acknowledges that the Service AXLRY provides is
                    expressly different, and independent from, the{" "}
                    <strong>User Services</strong> and other Third Party
                    Services. AXLRY&rsquo;s Service may be made available or
                    accessed in connection with another service that AXLRY does
                    not control (may include advertising). User acknowledges
                    that different terms of use and privacy policies may apply
                    to User&rsquo;s use of such other services and content. User
                    acknowledges and agrees that these Terms of Use are by and
                    between User and AXLRY only, and not with Apple, Inc.,
                    Google, Inc., Microsoft Corporation, Internet Service
                    Providers or Mobile service providers. AXLRY does not
                    endorse such other services and/or content and in no event
                    shall AXLRY be responsible or liable for any products or
                    services of such other providers. Additionally, Apple, Inc.,
                    Google, Inc., Microsoft Corporation, Internet Service
                    Providers or Mobile Service Providers, may be third-party
                    beneficiaries to this contract if User accesses the App
                    using a personal computer or device. These third-party
                    beneficiaries are not parties to this contract and are not
                    responsible for the provision or support of the Service in
                    any manner. User&rsquo;s access to the App using these
                    devices is subject to terms set forth in the applicable
                    third party beneficiary&rsquo;s terms of use and/or service.
                    Please see Third Party Beneficiaries Privacy Policies, Terms
                    of Use, Terms of Service, or End User License Agreements.
                </p>
                <p>
                    <br />
                </p>
                <ol start="4">
                    <li>
                        <strong> License</strong>
                    </li>
                </ol>
                <p>
                    Subject to User&rsquo;s compliance with these Terms, AXLRY
                    grants you a limited, non-exclusive, non-sublicenseable,
                    revocable, non-transferable license to: 1) access and use
                    the App on your mobile device or personal computer solely in
                    connection with User&rsquo;s use of the AXLRY Service; and
                    2) access and use the content, information and related
                    materials that is made available directly through the App
                    and/or Service, in each case solely for your personal use.
                    Any rights not expressly granted herein are reserved by
                    AXLRY.
                </p>
                <p>
                    <br />
                </p>
                <ol start="5">
                    <li>
                        <strong> Restrictions</strong>
                    </li>
                </ol>
                <p>
                    User agrees that User may not: 1) remove any copyright,
                    trademark or other proprietary notices from any portion of
                    the Service; 2) reproduce, modify, prepare derivative works
                    based upon, distribute, license, lease, sell, resell,
                    transfer, publicly display, publicly perform, transmit,
                    stream, broadcast or otherwise exploit the App, Service
                    except as expressly permitted by AXLRY; 3) decompile,
                    reverse engineer or disassemble the App or Service except as
                    may be permitted by applicable law; 4) link to, mirror or
                    frame any portion of the App or Service; 5) cause or launch
                    any programs or scripts for the purpose of indexing,
                    surveying, or data mining any portion of the App or Service
                    or unduly hindering the operation and/or functionality of
                    any aspect of the App or Service; or 6) attempt to gain
                    unauthorized access to or impair any aspect of the App or
                    Service or its related systems or networks.
                </p>
                <p>
                    <br />
                </p>
                <ol start="6">
                    <li>
                        <strong> Ownership</strong>
                    </li>
                </ol>
                <p>
                    Service and all rights therein are and shall remain
                    AXLRY&rsquo;s property or the property of AXLRY&rsquo;s
                    owners. Neither these Terms nor User&rsquo;s use of the App
                    or Service convey or grant User any rights: 1) in or related
                    to the App or Service except for the limited license granted
                    above; or 2) to use or reference in any manner AXLRY&rsquo;s
                    company names, logos, product and service names, trademarks,
                    or service marks or those of AXLRY&rsquo;s owners.
                </p>
                <p>
                    <br />
                    <br />
                    <br />
                </p>
                <ol start="7">
                    <li>
                        <strong> User Account:</strong>
                    </li>
                </ol>
                <p>
                    In order to use most aspects of the Service, User now must
                    register for and maintain an active personal User Account
                    <strong>(hereinafter &ldquo;Account&rdquo;)</strong>. User
                    must be at least eighteen (18) years of age, or the age of
                    legal majority in your jurisdiction (if different than 18),
                    to obtain an Account. Account registration requires User to
                    submit to AXLRY certain personal information and data such
                    as User&rsquo;s name or business entity name, address, email
                    address, and age, as well as at least one valid payment
                    method (either a credit card or accepted payment partner).
                    User agrees to maintain accurate, complete, and up-to-date
                    information on your Account. User&rsquo;s failure to
                    maintain such information, including having an expired or
                    invalid payment method on file, may result in User&rsquo;s
                    inability to access and use the Service, or AXLRY&rsquo;s
                    termination of these Terms of Use and agreement with User.
                    User is responsible for all activity that occurs under
                    User&rsquo;s Account, and User agrees to maintain the
                    security and secrecy of User Account, email, and password at
                    all times. Unless otherwise permitted by AXLRY in writing,
                    User may only possess one (1) Account. Again, for additional
                    information please see AXLRY&rsquo;s Privacy Policy.
                </p>
                <p>
                    <br />
                </p>
                <ol start="8">
                    <li>
                        <strong> User Requirements and Conduct</strong>
                    </li>
                </ol>
                <p>
                    <br />
                </p>
                <p>
                    The App and Services are not available for use by persons
                    under the age of 18. User may not authorize Third Parties to
                    use User&rsquo;s Account, and User may not allow persons
                    under the age of 18 to receive Service from Third Party
                    providers unless they are accompanied by a registered User
                    of the App. User may not assign or otherwise transfer
                    User&rsquo;s Account to any other person or entity, without
                    first obtaining AXLRY&rsquo;s written consent. User agrees
                    to comply with all applicable laws when using the Service,
                    and User may only use the Service for lawful purposes (e.g.
                    not allowed to use Service to commit any civil or criminal
                    crimes). User will not in the use of Service cause damages,
                    monetary or otherwise, to another User or any third party.
                </p>
                <p>
                    In certain instances, User may be asked to provide proof of
                    identity to access or use the App or Service. User agrees to
                    be denied Service if User refuses to provide proof of
                    identity.
                </p>
                <ol start="9">
                    <li>
                        <strong> User Provided Content</strong>
                    </li>
                </ol>
                <p>
                    AXLRY may, in AXLRY&rsquo;s sole discretion, ask or permit
                    User from time to time to submit, upload, publish or
                    otherwise make available to AXLRY through the Service
                    textual, audio, and/or visual content and information,
                    including but not limited to any information and content
                    related to the User&rsquo;s respective profile, information,
                    intellectual property, and or business entity (hereinafter
                    &ldquo;User Content&rdquo;). Any User Content provided by
                    User remains User&rsquo;s or User&rsquo;s business&rsquo;s
                    property. However, by providing such User Content, User is
                    granting AXLRY perpetual access to that User Content in any
                    manner which will help, benefit, inform, notify, or
                    otherwise AXLRY to better serve you as a User of the App and
                    Service. AXLRY reserves the right to access and use said
                    User Content at any time, for any reason, without further
                    consent from User. Should the occasion arise where User no
                    longer wants to permit AXLRY or other Users to have access
                    to any said User Content, User agrees and acknowledges that
                    User will immediately notify and inform AXLRY of
                    User&rsquo;s intent to revoke access. AXLRY represents and
                    warrants that AXLRY will address and field any and all
                    requests by Users or User&rsquo;s Business within twenty
                    (20) business days.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    User represents and warrants that: 1) User is either the
                    sole and exclusive owner of all User Content, or User has
                    all rights, licenses, consents and releases necessary to
                    grant AXLRY and other Users the license to access and use
                    the User Content as set forth above, and 2) neither the User
                    Content nor User&rsquo;s submission, uploading, publishing
                    or otherwise making available such User Content, nor
                    AXLRY&rsquo;s access or use of the UserContent as permitted
                    herein will infringe, misappropriate, or violate another
                    User or Third Party&rsquo;s intellectual property or
                    proprietary rights, or rights of publicity or privacy, or
                    rights of public display or performance, or result in the
                    violation of any applicable law or regulation.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    User agrees to not provide User Content that is defamatory,
                    libelous, hateful, violent, obscene, pornographic, unlawful
                    for any purpose, or otherwise offensive, as determined by
                    AXLRY in their sole discretion respectively, whether or not
                    such User Content or material is protected by law. AXLRY
                    may, but not be obligated to review, monitor, or remove User
                    Content at AXLRY&rsquo;s sole discretion at any time and for
                    any reason without notice to User.
                </p>
                <p>
                    <br />
                    <br />
                    <br />
                </p>
                <ol start="10">
                    <li>
                        <strong> Network Access and Devices</strong>
                    </li>
                </ol>
                <p>
                    User is responsible for obtaining the data network or
                    internet access necessary to use the App, Service, or User
                    Services. AXLRY will not provide network or internet access
                    for User. If User is using a personal mobile device,
                    User&rsquo;s mobile network&rsquo;s data and messaging rates
                    and fees may apply if User accesses or uses the App or
                    Service from a wireless-enabled device. User is responsible
                    for acquiring and updating compatible hardware or devices
                    necessary or may be necessary to access the App or Service,
                    and any updates thereto. AXLRY does not guarantee that the
                    App or Service portions thereof, will function on any
                    particular hardware, device, computer, or other electronic
                    devices. In addition, the App and Service may be subject to
                    malfunctions and delays inherent in the use of Internet,
                    network, and electronic communications.
                </p>
                <p>
                    <br />
                    <br />
                    <br />
                </p>
                <ol start="11">
                    <li>
                        <strong> Payment Terms</strong>
                    </li>
                </ol>
                <p>
                    User understands that the use of the App, Service and/or
                    User or Third Party Services may, and often will, result in
                    monetary charges (hereinafter &ldquo;Charges&rdquo;) to User
                    for the Service and/or User Services User receives from
                    AXLRY or another User. However, User understands and
                    acknowledges that AXLRY will facilitate any payments between
                    User and AXLRY only, and AXLRY will <strong>NOT</strong>{" "}
                    facilitate payments of the applicable Charges between User
                    to User, or User to Third Party. For the avoidance of doubt,
                    AXLRY is not responsible or liable for any transaction or
                    Charges between Users at any time. Further User understands
                    and acknowledges that AXLRY will NOT act as User&rsquo;s
                    limited payment collection agent or depository. Such
                    facilitation of payment of Charges has been agreed to by
                    User and AXLRY pursuant to these Terms.
                </p>
                <p>
                    Charges will be inclusive of applicable taxes where required
                    by law. Charges paid by User are final and non-refundable at
                    all times regardless of User&rsquo;s decision to terminate
                    use, unless otherwise determined by AXLRY. AXLRY retains the
                    right to request any sum of Charges from a User in return
                    for providing Service to user. User may request, but is not
                    guaranteed any modification or adjustment of Charges from
                    AXLRY. AXLRY will respond accordingly to any request
                    received from a User to modify the Charges for a particular
                    service received by User within twenty (20) business days of
                    receiving the request.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    All Charges arising between User and AXLRY are due
                    immediately and payment will be facilitated by AXLRY using
                    the preferred payment method designated in User&rsquo;s
                    Account, after which AXLRY will send User a receipt by
                    email. If User&rsquo;s primary Account payment method is
                    determined to be expired, invalid or otherwise not able to
                    be processed or charged, AXLRY may use a secondary payment
                    method in User&rsquo;s account, if available. If AXLRY still
                    cannot process or charge payment for the Service provided to
                    User, AXLRY will immediately notify User of the issue and
                    reserves the right to withhold further Service until any and
                    all such payment and Charges issues are resolved to
                    AXLRY&rsquo;s satisfaction.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    As between User and AXLRY, AXLRY reserves the right to
                    establish, remove and/or revise Charges for any and all
                    service obtained through the use of the App, Service at any
                    time in AXLRY&rsquo;s sole discretion. AXLRY will use
                    reasonable efforts to inform and notify User of Charges that
                    may apply, provided that you will be responsible for Charges
                    incurred under User&rsquo;s Account regardless of
                    User&rsquo;s awareness of such Charges or the amounts
                    thereof. User may elect to cancel User&rsquo;s request for
                    Service from AXLRY at any time prior to AXLRY or a User
                    providing Service or User Services.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    This payment structure, and all applicable Charges, are
                    intended to fully compensate AXLRY and a User for the
                    Service.
                </p>
                <p>
                    <br />
                </p>
                <ol start="12">
                    <li>
                        <strong> DISCLAIMERS</strong>
                    </li>
                </ol>
                <p>
                    THE APP AND SERVICE AND/OR USER SERVICES ARE PROVIDED
                    &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo; AXLRY
                    DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS,
                    IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS,
                    INCLUDING THE IMPLIED WARRANTY OF FITNESS FOR A
                </p>
                <p>
                    <br />
                </p>
                <p>
                    PARTICULAR PURPOSE AND NON-INFRINGEMENT, AS WELL AS
                    WARRANTIES IMPLIED FROM A COURSE OF PERFORMANCE OR COURSE OF
                    DEALING. IN ADDITION, AXLRY, IT&rsquo;S OWNERS, OFFICERS,
                    DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS AND ASSIGNS, MAKE
                    NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE
                    ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS, QUALITY,
                    SUITABILITY, OR AVAILABILITY OF THE OPERATION OF THE APP,
                    SERVICE, OR USER SERVICES, REQUESTED BY USER THROUGH THE USE
                    OF THE APP, SERVICE, OR THAT THE APP, SERVICE, USER SERVICES
                    WILL MEET USER&rsquo;S REQUIREMENTS OR EXPECTATIONS, OR WILL
                    BE UNINTERRUPTED OR ERROR-FREE. AXLRY, IT&rsquo;S OFFICERS,
                    DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS AND ASSIGNS DOES
                    NOT GUARANTEE THE QUALITY, SUITABILITY, OR ABILITY OF ANY
                    USER OR ANY THIRD PARTY.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    USER AGREES THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF
                    THE APP, SERVICE, AND ANY SERVICE REQUESTED IN CONNECTION
                    THEREWITH, REMAINS SOLELY WITH USER, TO THE MAXIMUM EXTENT
                    PERMITTED UNDER APPLICABLE LAW.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    THE DISCLAIMERS IN THIS SECTION TWELVE (12) ABOVE DO NOT
                    PURPORT TO LIMIT LIABILITY OR ALTER USER&rsquo;S RIGHTS AS A
                    CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
                </p>
                <p>
                    <br />
                </p>
                <ol start="13">
                    <li>
                        <strong> LIMITATION OF LIABILITY</strong>
                    </li>
                </ol>
                <p>
                    IN NO EVENT SHALL AXLRY, IT&rsquo;S OWNERS, OFFICERS,
                    DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS OR ASSIGNS, BE
                    LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
                    PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS,
                    LOST BUSINESS, LOST OPPORTUNITY, LOST DATA OR INABILITY TO
                    ACCESS DATA, OR OTHERWISE ARISING OUT OF, RELATED TO, OR IN
                    CONNECTION WITH ANY OF THE FOLLOWING: 1) USER&rsquo;S USE
                    OF, OR RELIANCE ON, THE APP, SERVICE OR USER SERVICES, THIRD
                    PARTY SERVICES, THE CONTENT, USER CONTENT, INCLUDING WITHOUT
                    LIMITATION, ANY PERSONAL INFORMATION, AND ANY OTHER
                    INFORMATION, INTELLECTUAL PROPERTY, BUSINESS RELATED OR
                    OTHERWISE, EITHER CONTAINED IN THE SITE OR SUBMITTED BY USER
                    TO THE SITE OR USER ACCOUNT; 2) USER&rsquo;S ABILITY OR
                    INABILITY TO USE THE APP; 3) USER&rsquo;S ABILITY OR
                    INABILITY TO USE OF THE SERVICE; 4) USER&rsquo;S ABILITY OR
                    INABILITY TO USE OF, OR IMPLEMENTATION OF USER SERVICES FROM
                    ANOTHER USER; 5) ANY TRANSACTION OR RELATIONSHIP BETWEEN
                    USER AND ANOTHER USER, EVEN
                </p>
                <p>
                    IF AXLRY KNOWS OF THE RELATIONSHIP OR TRANSACTION; 6) ANY
                    PRODUCTS OR SERVICE OBTAINED FROM A THIRD PARTY NOT PRIVY TO
                    THESE TERMS; 7)THESE TERMS OF USE; OR 8) ANY IMPROPER USE OF
                    USER CONTENT OR INFORMATION USER PROVIDES TO THE APP, AXLRY,
                    OR ANY OTHER USER.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    AXLRY SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN
                    PERFORMANCE RESULTING FROM CAUSES BEYOND AXLRY&rsquo;S
                    REASONABLE CONTROL. IN NO EVENT WILL AXLRY&rsquo;S LIABILITY
                    IN CONNECTION WITH THE APP OR SERVICE EXCEED THE AMOUNTS
                    PAID BY USER TO AXLRY FOR THE APPLICABLE RESPECTIVE SERVICE.
                    TOTAL AGGREGATE LIABILITY ARISING OUT OF, RELATED TO, OR IN
                    CONNECTION WITH ANY OF THE ABOVE SHALL NOT EXCEED THE
                    AMOUNTS PAID BY USER TO AXLRY DURING THE SIX (6) MONTHS
                    PRECEDING THE EVENT GIVING RISE TO THE BRINGING OF ANY
                    CLAIM. IN THE EVENT WHERE THE USE OF THE APP OR SERVICE IS
                    FREE OF CHARGE, AXLRY SHALL NOT BE RESPONSIBLE TO THE USER
                    FOR ANY MONETARY COMPENSATION. USER AGREES THAT ANY CAUSE OF
                    ACTION OR CLAIM ARISING OUT OF, RLATED TO, OR IN CONNECTION
                    WITH ANY OF THE ABOVE, MUST COMMENCE WITHIN ONE-YEAR (1)
                    AFTER THE CAUSE OF ACTION OR CLAIM OCCURS, OR THE CAUSE OF
                    ACTION OR CLAIM IS PERMANENTLY BARRED. BECAUSE SOME
                    JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN
                    IMPLIED WARRANTY LASTS, ALL OR A PORTION OF THE ABOVE
                    LIMITATION MAY NOT APPLY TO YOU.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    AXLRY MAY ALLOW USER TO DISCOVER, MATCH AND COMMUNICATE WITH
                    ANOTHER USER FOR THE PURPOSES OF PROVIDING USER SERVICES TO
                    USER. AXLRY WILL NOT ASSESS THE SUITABILITY, LEGALITY OR
                    ABILITY OF ANY USER, AND USER EXPRESSLY WAIVES AND RELEASES
                    AXLRY FROM ANY AND ALL LIABILITY, CLAIMS OR DAMAGES ARISING
                    FROM OR IN ANY WAY RELATED TO THE USERS. AXLRY&rsquo;S APP
                    AND SERVICE MAY BE USED BY USER AS A PLATFORM TO BROWSE FOR,
                    DISCOVER, AND CONNECT WITH ANOTHER USER WHO MAY BE ANOTHER
                    USER OR THIRD PARTY PROVIDERS OF SERVICE TO USER. BUT USER
                    AGREES THAT AXLRY HAS NO RESPONSIBILITY OR LIABILITY TO
                    USER, RELATED TO ANY USER OR THIRD PARTY SERVICES OR OTHER
                    SIMILAR SERVICE PROVIDED TO USER BY ANOTHER USER, OR OTHER
                    THIRD PARTIES, OTHER THAN EXPRESSLY SET FORTH IN THESE
                    TERMS. IN THE EVENT THAT USER ELECTS USER SERVICES, USER
                    DOES SO AT THEIR OWN RISK. USER UNDERSTANDS AND ACKNOWLEDGES
                    THAT A USER AND THIRD PARTY SERVICE ARE NOT UNDER
                    AXLRY&rsquo;S CONTROL AND THAT AXLRY CANNOT BE RESPONSIBLE
                    OR LIABLE FOR SUCH SERVICE
                </p>
                <p>
                    THE LIMITATIONS IN THIS SECTION THIRTEEN (13) ABOVE DO NOT
                    PURPORT TO LIMIT LIABILITY OR ALTER USER&rsquo;S RIGHTS AS A
                    CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
                </p>
                <p>
                    <br />
                </p>
                <ol start="14">
                    <li>
                        <strong> INDEMNITY</strong>
                    </li>
                </ol>
                <p>
                    USER AGREES TO INDEMNIFY, DEFEND AND HOLD HARMLESS AXLRY AND
                    ITS OWNERS, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
                    SUCCESSORS, AND ASSIGNS FROM ANY AND ALL CLAIMS, DEMANDS,
                    LOSSES, LIABILITIES AND EXPENSES, (INCLUDING REASONABLE
                    ATTORNEY&rsquo;S FEES), ARISING OUT OF, RELATED TO, OR IN
                    CONNECTION WITH: 1) USER&rsquo;S USE OF THE APP OR SERVICE,
                    AND/OR USER SERVICES, AND/OR THIRD PARTY SERVICES OBTAINED
                    THROUGH USER&rsquo;S USE OF THE APP, SERVICE AND/OR USER
                    SERVICES; 2) USER&rsquo;S BREACH OR VIOLATION OF ANY OF
                    THESE TERMS;
                </p>
                <ol>
                    <li>
                        AXLRY&rsquo;S USE OR ACCESS OF USER CONTENT; OR 4)
                        USER&rsquo;S VIOLATION OF ANY APPLICABLE LAWS, OR THE
                        RIGHTS OF ANY THIRD PARTY, INCLUDING A AXLRY USER.
                    </li>
                </ol>
                <p>
                    <br />
                </p>
                <p>
                    TO THE EXTENT PERMITTED BY ALL APPLICABLE LAWS, USER HEREBY
                    RELEASES AXLRY FROM ANY AND ALL CLAIMS OR LIABILITY RELATED
                    TO ANY USE OF THE APP, THE SERVICE OR OTHER USER SERVICES,
                    OF A AXLRY USER, OR OTHER THIRD PARTY, REGARDLESS OF WHETHER
                    SUCH SERVICE IS A OFFERING MADE AVAILABLE BY AXLRY THROUGH
                    THE APP AND/OR SERVICE, INCLUDING WITHOUT LIMITATION TO ANY
                    HARM CAUSED TO USER BY ACTION OR INACTION OF A AXLRY USER,
                    OR OTHER THIRD PARTY, WHETHER THROUGH THE AXLRY SERVICE OR
                    NOT, ONLINE OR OFFLINE.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    THE TERMS OF THIS SECTION SHALL SURVIVE THE TERMINATION OR
                    EXPIRATION OF THIS AGREEMENT.
                </p>
                <p>
                    <br />
                </p>
                <ol start="15">
                    <li>
                        <strong> Dispute Resolution</strong>
                    </li>
                </ol>
                <p>
                    User agrees that any dispute, claim or controversy, arising
                    out of or relating to these Terms, or the breach,
                    termination, enforcement, interpretation or validity
                    thereof, or the use of the App, Service and/or User Services
                    and/or Third Party Services{" "}
                    <strong>(collectively &ldquo;Disputes&rdquo;)</strong>, that
                    names AXLRY, LLC as a party, may only be instituted ina
                    state or federal court in Cook County, Illinois. User
                    irrevocably consents and submits to the exclusive personal
                    jurisdiction and venue of such courts for resolution of such
                    Disputes.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    If any portion or provision of Section Fifteen (15) above is
                    determined to be unenforceable, than the remainder of
                    Section Fifteen (15) shall remain in full force and effect,
                    and shall be enforced as if the portion ruled unenforceable
                    were not contained herein.
                </p>
                <p>
                    <br />
                </p>
                <ol start="16">
                    <li>
                        <strong> Choice of Law</strong>
                    </li>
                </ol>
                <p>
                    These Terms are governed and construed in accordance with
                    the laws of the State of Illinois, U.S.A., without giving
                    effect to any conflict of law principles, or actual state or
                    country of residence.
                </p>
                <p>
                    <br />
                </p>
                <ol start="17">
                    <li>
                        <strong> General</strong>
                    </li>
                </ol>
                <p>
                    User may not assign these Terms without AXLRY&rsquo;s prior
                    written approval. AXLRY may assign these Terms without
                    User&rsquo;s consent to: 1) a subsidiary or affiliate; 2) an
                    acquirer of AXLRY&rsquo;s equity, business or assets; 3) a
                    successor by merger. Any purported assignment in violation
                    of this section shall be void. No joint venture,
                    partnership, employment, or agency relationship exists
                    between User and AXLRY as a result of these Terms. If any
                    section, provision or portion is held to be invalid or
                    unenforceable, such provision shall be struck and the
                    remaining provisions shall be enforced to the fullest extent
                    under law. AXLRY&rsquo;s failure to enforce any right or
                    provision in these Terms shall not constitute a waiver of
                    such right or provision unless acknowledged and agreed to by
                    AXLRY in writing.
                </p>
                <p>
                    <br />
                </p>
                <ol start="18">
                    <li>
                        <strong> Entire Agreement</strong>
                    </li>
                </ol>
                <p>
                    These Terms of Use are between AXLRY and User, constitute
                    the entire agreement and understanding between User and
                    AXLRY. The subject matter hereof supersedes all
                    contemporaneous communications, proposals and agreements,
                    whether written or oral, between User and AXLRY.
                </p>
                <p>END OF AXLRY CONSULTING, LLC TERMS OF USE</p>
            </div>
        </Layout>
    );
};

export default Terms;
